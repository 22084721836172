// import Axios from 'axios'
// import Vue from 'vue'
// import { getToken } from '@/api/user'
import axios from '@/utils/axios.js'

// let axios = Axios.create({
//     // baseURL: process.env.NODE_ENV === 'development' ? '/development' : 'http://cloudhospital.knjs.com.cn', // url = base url + request url
//     baseURL: process.env.NODE_ENV === 'development' ? 'https://cloudhospitalapi.knjs.net' : process.env.VUE_APP_API_BASE_URL, // url = base url + request url
//     withCredentials: false, // send cookies when cross-domain requests
//     timeout: 5000 // request timeout
// })
//
// // 添加请求拦截器
// axios.interceptors.request.use(
//     config => {
//         // 在发送请求之前做些什么
//         // if (store.getters.token) {
//         // let each request carry token
//         // ['X-Token'] is a custom headers key
//         // please modify it according to the actual situation
//         // config.headers['X-Token'] = store.getters.token;
//         config.headers['hosId'] = '1'
//         // config.headers['x-access-token'] = '123';
//         config.headers['token'] = localStorage.getItem('access_token')
//         config.headers['Access-Control-Allow-Origin'] = '*'
//         config.headers['mode'] = 'no-cors'
//         // console.log(config)
//         // }
//         return config
//     },
//     error => {
//         // 对请求错误做些什么
//         console.log(error) // for debug
//         return Promise.reject(error)
//     }
// )
//
// axios.interceptors.response.use(
//     /**
//      * If you want to get http information such as headers or status
//      * Please return  response => response
//      */
//
//     /**
//      * Determine the request status by custom code
//      * Here is just an example
//      * You can also judge the status by HTTP Status Code
//      */
//
//     response => {
//         // 对响应数据做点什么
//         console.log(response)
//         // if the custom code is not 20000, it is judged as an error.
//         if (response.data.resultStatus !== 200) {
//             if (response.data.resultStatus !== 301) {
//                 //请求失败
//                 Vue.prototype.$dialog.alert({
//                     message: response.data.msg || 'error',//提示内容
//                     confirmButtonText: (response.data.msg.indexOf("当前账户没有默认账户") == "-1" || response.data.msg.indexOf("当前默认账户无效") == "-1") ? "确认" : "立即设置",
//                     // duration: 5 * 1000 //自动关闭的延时，单位秒，不关闭可以写 0
//                 }).then(() => {
//                     // on confirm
//                     if (response.data.msg.indexOf("当前账户没有默认账户") != "-1" || response.data.msg.indexOf("当前默认账户无效") != "-1") {
//                         window.location.replace(window.location.origin + window.location.search + "#/accountJudgment/0/0/0")
//                     }
//                 })
//             } else {
//                 Vue.prototype.$toast(response.data.msg || "未查询到数据")
//             }
//             return Promise.reject(response.data.msg || 'error')
//         } else {
//             //请求成功
//             // if(res.msg) {
//             // 	Vue.prototype.$toast(res.msg);
//             // }
//             return response
//         }
//     },
//     error => {
//         if (error.message.includes(401)) {
//             getToken().then(res => {
//                 localStorage.setItem('access_token', res.token)
//                 localStorage.setItem('access_refresh', res.refresh)
//             })
//         }
//         // 对响应错误做点什么
//         Vue.prototype.$dialog.alert({
//             message: error.message,//提示内容
//             // duration: 5 * 1000 //自动关闭的延时，单位秒，不关闭可以写 0
//         })
//         return Promise.reject(error)
//     }
// )

export function GetHisInformation(lastSendDate, senderID, targetId, num) {
    return axios.get('/api/User/GetUserHisChatMsgBySendDateDecreasing?lastSendDate=' + lastSendDate + '&senderID=' + senderID + '&targetId=' + targetId + '&num=' + num)
}

export function GetJZLB(CardID, index) {
    return axios.get('/api/DrugLogistic/GetRecordList?CardID=' + CardID + '&index=' + index + '&size=10')
}








